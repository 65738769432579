import { DISPLAY } from 'html2canvas/dist/types/css/property-descriptors/display';

export const ConstValues = {
  PRINT_PENDING: 'PENDING',
  PRINT_OPEN: 'OPEN',
  PRINT_CREATED: 'CREATED',
  PRINT_SENTTOPRESS: 'SENTTOPRESS',
  PRINT_ONDIECUTTER: 'ONDIECUTTER',
  PRINT_ATREWINDER: 'ATREWINDER',
  PRINT_WAITINGTOBECUT: 'PRINTED_WAITINGTOCUT',
  PRINT_SHIPPING: 'SHIPPING',
  PRINT_SHIPPED: 'SHIPPED',
  PRINT_READYTOINVOICE: 'READYTOINVOICE',
  PRINT_WAITINGONPAYMENT: 'WAITINGONPAYMENT',
  PRINT_INVOICECREATED: 'INVOICECREATED',
  PRINT_PAIDINTRANSIT: 'PAIDINTRANSIT',
  PRINT_CLOSED: 'CLOSED',
  DELIVERY_OPTIONS: {
    DEFAULTRUSHLEVEL1DELIVERYOPTIONID: 6,
    DEFAULTRUSHLEVEL2DELIVERYOPTIONID: 4,
    DEFAULTRUSHLEVEL4DELIVERYOPTIONID: 3,
    DEFAULTNONINTERNATIONALOPTIONID: 1,
    DEFAULTRUSHFORINTERNATIONALOPTIONID: 8,
    DEFAULTFORINTERNATIONALOPTIONID: 9
  },
  DEFAULT_COUNTRY_ID: 1,
  COUNTRY_USA: 'USA',
  CUSTOMER_PICKUP_ADDRESS_ID: '72E1C510-28A9-41DE-8565-527B34F1B848',
  DEFAULT_MAX_OD: 12,
  CUSTOMER_SHIP_TO_DUMMY_ID: '31097585-8720-4694-9246-c56a54fe6fa1',
  DISPLAY_STYLE_LIST: 'list',
  DISPLAY_STYLE_CARD: 'card',
  COOKIE_NAME_DISPLAY_STYLE: 'displayStyle'
};

export const enum VarDataDynamicTextTypes {
  None = 0,
  PublicCode = 1,
  UPC = 2,
  SKU = 3,
  ASIN = 4,
  Custom = 5,
  PartNumber = 6,
  Ean13 = 7,
  FNSKU = 8,
  Ean8 = 9,
  Ean14 = 10,
  Ucc14 = 11
}

export const enum OrderlinePreflightErrorCodes {
  MissingBillingAddress,
  ShippingAddressNotComplete
}

export const enum RequestedOrderlineMetricTypes {
  None,
  RollQuantity,
  MaxLabelsPerRoll,
  RollDirection
}

export const enum NoAccessTypes {
  NotConfirmed = 0,
  Disabled,
  CreditHold
}

export enum BankAccountType {
  Checking = 0,
  BusinessChecking
}

export const enum PrintStatusValues {
  ChangeCopy = 0,
  Hold = 0,
  Created = 0,
  Pending = 0,
  Open,
  Queued,
  SentToPress = 4,
  Printed = 4,
  Printed_WaitingToCut = 4,
  OnDieCutter = 4,
  AtRewinder = 4,
  Shipping,
  Shipped,
  ReadyToInvoice,
  InvoiceCreated,
  WaitingOnPayment,
  PaidInTransit,
  Closed = 20,
  Cancel = 21
}

export const enum OrderlineServiceTypes {
  None = 0,
  GraphicsDesign,
  Rewinding
}

export const BankAccountTypes: Array<{ Name: string; Type: BankAccountType }> = [
  { Name: 'Personal Checking', Type: BankAccountType.Checking },
  { Name: 'Business Checking', Type: BankAccountType.BusinessChecking }
];
